<template>
  <v-row justify="start">
    <v-dialog v-model="d_view" scrollable persistent max-width="800px">
      <v-card class="radius-card" v-if="pocket">
        <v-toolbar dense flat>
          <span class="headline">Agenda</span>
          <v-spacer></v-spacer>
          <v-btn fab x- small depressed @click="$emit('close')"
            ><v-icon small>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <v-col>
              <v-row>
                <v-col cols="12" md="3">Judul</v-col>
                <v-col cols="12" md="7">{{ pocket.data.judul }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">Deskripsi</v-col>
                <v-col cols="12" md="7">{{ pocket.data.deskripsi }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">Lokasi</v-col>
                <v-col cols="12" md="7">{{ pocket.data.lokasi }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">Kategori</v-col>
                <v-col cols="12" md="7">{{ pocket.data.nama_kategori }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">
                  <p>Waktu</p>
                </v-col>

                <v-col cols="12" md="7">
                  <v-row>
                    <v-col cols="12" md="6">
                      <p class="ma-0">waktu mulai</p>
                      <p>
                        {{ $date(pocket.data.start_date).format("DD-MM-YYYY") }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="ma-0">waktu selesai</p>
                      <p>
                        {{ $date(pocket.data.end_date).format("DD-MM-YYYY") }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">Fasilitas</v-col>
                <v-col cols="12" md="7">{{ pocket.data.fasilitas }}</v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3">Pemateri</v-col>
                <v-col cols="12" md="7">{{ pocket.data.pemateri }}</v-col>
              </v-row>
            </v-col>
            <v-img
              v-if="pocket.data.img"
              :src="pocket.data.img"
              class="img-fit"
            ></v-img>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "viewAgenda",

  props: ["d_view", "pocket"],
  computed: {
    ...mapState({
      env: (state) => state.API_URL,
      agenda: (state) => state.agenda.agendaDetail,
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>

<style scoped>
.temp-cover {
  width: 100%;
  height: 200px;
  border: 1px #000 dotted;
}
</style>
