<template>
  <div class="_bg-default _80w _100vh d-flex">
    <div class="_100top _full-w pa-3">
      <v-card>
        <v-tabs
          v-model="tab"
          class="mb-5 rounded"
          background-color="purple lighten-5"
          color="purple"
          grow
        >
          <v-tab href="#tab-1"
            ><v-icon>mdi-calendar-text</v-icon><b>List Event</b></v-tab
          >
          <v-tab href="#tab-2"
            ><v-icon>mdi-calendar-check</v-icon><b>Participated</b></v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="tab" style="background: none">
          <v-tab-item value="tab-1">
            <ListAgenda />
          </v-tab-item>
          <v-tab-item value="tab-2">
            <JoinedAgenda />
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
import ListAgenda from "./listAgenda.vue";
import JoinedAgenda from "./joinedAgenda.vue";
export default {
  data() {
    return {
      tab: null,
      listEvent: [],
      listJoin: [],
      loadList: false,
      loadJoin: false,
    };
  },
  name: "userPublicAgenda",
  components: { ListAgenda, JoinedAgenda },
  mounted() {
    this.getListEvent();
  },
  methods: {
    getListEvent() {
      this.loadList = true;
      let data = {
        path: "general/agenda",
      };
      this.$store.dispatch("getApi", data).then((data) => {
        this.listEvent = data;
        this.loadList = false;
      });
    },
    getJoinedEvent() {
      this.loadList = true;
      let data = {
        path: "general/agenda",
      };
      this.$store.dispatch("getApi", data).then((data) => {
        this.listEvent = data;
        this.loadList = false;
      });
    },
  },
};
</script>

<style></style>
